@import "../../stylesheets/base";

.order-summary-block {
  $root: &;
  margin-top: $spacing-150;

  &__total-cost {
    margin: $spacing-150 0 $spacing-150 $spacing-450;
    padding: $spacing-150 0 0;
    border-bottom: 1px solid $ui-200;
    border-top: 1px solid $ui-200;
  }

  &__total-cost-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: $ui-600;
    margin-bottom: $spacing-50;
  }

  &__total {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: $spacing-150;
  }

  &__total-label {
    font-weight: 500;
    color: $ui-1000;
  }

  &__amount {
    padding-top: $spacing-50;
    font-family: $font-heading;
    font-weight: Bold;
    font-size: #{rem(25)};
    line-height: #{rem(32)};
  }

}
