@import "../../stylesheets/base";

.prescription-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &__label {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    gap: 0.5rem;
  }

  &__rich-text-wrapper {
    background: $ui-200;
    box-shadow: 0 0 0 2px $ui-200;
    color: $ui-700;
    border-width: 1px;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    opacity: 0.5;
  }

  &__gphc-tooltip {
    position: relative;

    &__message {
      display: none;
      padding: 1rem;
      width: 300px;
      z-index: 1000;
      position: absolute;
      top: 120%;
      left: calc(-150px + 50%);
      background-color: white;
      border: 1px solid $accent-500;
      box-shadow: 0 4px 16px 0 $ui-300;
      border-radius: 0.5rem;
    }

    &__icon {
      width: 24px;
      height: 24px;

      svg {
        width: 24px;
        height: 24px;
      }

      :hover {
        cursor: pointer;
      }
    }

    &:hover {
      .prescription-form__gphc-tooltip__message {
        display: block;
      }
    }
  }

  &__buttons {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  &__prescribed-medication {
    min-height: 150px;
  }

  &__inputs {
    margin: 0;
  }

  &__modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    * {
      margin: 0;
    }
  }
}
