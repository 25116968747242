@import "../../stylesheets/base";

.pagination {
  $root: &;
  display: flex;
  align-items: center;
  margin: 0 $spacing-200;

  &__text {
    display: block;
    min-width: $spacing-1000;
    margin: 0 $spacing-150;
    text-align: center;
  }

  &__forward {
    svg {
      transform: rotate(180deg);
    }
  }
}
