@import "../../stylesheets/base";

.group-item-list {
  $root: &;
  margin-top: $spacing-100;

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-150;
    padding-bottom: #{rem(16)};

    &:not(:last-of-type) {
      border-bottom: 1px solid #dbe0e5;
    }
  }

  &__text {
    margin-bottom: 0;
  }

  &__delete {
    margin-left: auto;
  }

  &__button {
    margin-top: $spacing-200;
    text-align: right;
  }
}
