.page {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__main {
    flex-grow: 1;
    overflow: auto;
  }
}
