@import "../../../stylesheets/base";


/*
name
solid background color
solid color
solid background hover
soild pressed
solid focus
box shadow outer
box shadow inner
outline border
minimal border and flat background, ghost hover
minimal, flat and ghost color
flat hovver border, active background color
minimal, flat, ghost focus border
minimal flat, and ghost active text color
pressed text color
flat hovver background
*/
$variants: (
  ui $ui-1000 $ui-0 $ui-800 $ui-800 $accent-600 $ui-0 $ui-1000 $ui-1000 $ui-200 $ui-1000
  $ui-300 $accent-600 $ui-1000 $ui-200 $ui-300 $ui-800,
  accent $accent-500 $ui-0 $accent-700 $accent-700 $accent-600 $ui-0 $accent-500 $accent-500
  $accent-200 $accent-700 $accent-300 $accent-600 $accent-800 $accent-200 $accent-300 $accent-900,
  negative $negative-500 $ui-0 $negative-700 $negative-700 $negative-600 $ui-0 $negative-500 $negative-700
  $negative-200 $negative-700 $negative-300 $negative-600 $negative-800 $negative-200 $negative-300 $negative-900
);

$sizes: (
  sm $spacing-100 $spacing-150,
  md $spacing-150 $spacing-250,
  lg $spacing-150 $spacing-250
);

.btn {
  $root: &;
  position: relative;
  display: inline-block;
  appearance: none;
  outline: none;
  border: none;
  font-family: $font-heading;
  font-weight: 600;
  font-size: #{rem(19)};
  line-height: #{rem(24)};
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  margin-bottom: $spacing-100;

  &__text {
    margin: 0;

    &--loading {
      color: transparent;
    }
  }

  &--disabled {
    opacity: 0.32;
  }

  &--full {
    width: 100%;

    @media screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-lg - 1) {
      flex-grow: 1;
      min-width: calc(50% - #{$spacing-50});
      margin-bottom: 0;

      &:nth-child(even) {
        margin-right: $spacing-100;
      }
    }

    @media screen and (min-width: $breakpoint-lg) {
      width: auto;
      flex-grow: unset;

      &:not(:last-child) {
        margin-left: $spacing-100;
      }
    }
  }

  &--grow {
    flex-grow: 0;
  }

  &--loading {
    color: transparent;
  }

  &--rounded {
    border-radius: $spacing-100;
  }

  &--pill {
    border-radius: $spacing-350;
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-top: -2px;
  }

  @each $size in $sizes {
    &--#{nth($size, 1)} {
      padding: #{nth($size, 2)} #{nth($size, 3)};

      .btn__icon {
        left: #{nth($size, 3)};
      }
    }

    &--#{nth($size, 1)}.btn--icon {
      padding-left: #{nth($size, 3) + $spacing-300 + $spacing-50};

    }

  }

  &--solid {
    @each $variant in $variants {
      &--#{nth($variant, 1)} {
        background: #{nth($variant, 2)};
        box-shadow: 0 0 0 1px #{nth($variant, 2)};
        color: #{nth($variant, 3)};

        &:hover {
          background: #{nth($variant, 4)};
          box-shadow: 0 0 0 1px #{nth($variant, 4)};
        }

        &:active {
          background: #{nth($variant, 5)};
          color: #{nth($variant, 15)};
          box-shadow: 0 0 0 1px #{nth($variant, 5)};
        }

        &:focus {
          box-shadow:
            0 0 0 3px #{nth($variant, 6)},
            inset 0 0 0 1px #{nth($variant, 7)};
        }
      }
    }
  }

  &--flat {
    @each $variant in $variants {
      &--#{nth($variant, 1)} {
        background: #{nth($variant, 10)};
        box-shadow: 0 0 0 1px #{nth($variant, 10)};
        color: #{nth($variant, 11)};

        &:hover {
          box-shadow: 0 0 0 1px #{nth($variant, 12)};
          background: #{nth($variant, 16)};
        }

        &:active {
          background: #{nth($variant, 12)};
          color: #{nth($variant, 14)};
          box-shadow: 0 0 0 1px #{nth($variant, 12)};
        }

        &:focus {
          box-shadow: 0 0 0 3px #{nth($variant, 13)};
        }
      }
    }
  }

  &--minimal {
    @each $variant in $variants {
      &--#{nth($variant, 1)} {
        background: #{nth($variant, 7)};
        box-shadow: 0 0 0 1px #{nth($variant, 10)};
        color: #{nth($variant, 11)};
        font-weight: 300;

        &:hover {
          background: #{nth($variant, 10)};
        }

        &:active {
          background: #{nth($variant, 12)};
          box-shadow: 0 0 0 1px #{nth($variant, 12)};
          color: #{nth($variant, 14)};
        }

        &:focus {
          box-shadow: 0 0 0 3px #{nth($variant, 13)};
        }
      }
    }
  }

  &--ghost {
    @each $variant in $variants {
      &--#{nth($variant, 1)} {
        background: #{nth($variant, 7)};
        box-shadow: 0 0 0 1px #{nth($variant, 7)};
        color: #{nth($variant, 11)};

        &:hover {
          background: #{nth($variant, 10)};
          box-shadow: 0 0 0 1px #{nth($variant, 10)};
        }

        &:active {
          background: #{nth($variant, 10)};
          color: #{nth($variant, 17)};
        }

        &:focus {
          box-shadow: 0 0 0 3px #{nth($variant, 13)};
        }
      }
    }
  }

  &--link--accent {
    background: 0;
    padding: 0;
    color: $accent-500;
    font-family: $font-body;
    font-size: #{rem(16)};
    font-weight: normal;
    text-decoration: underline;
    display: inline-block;
    margin-left: $spacing-50;
  }
}
