@import "../../stylesheets/base";

.autocomplete {
  position: relative;

  &__input {
    margin-bottom: 0;
  }

  &__label {
    @include sr-only();
  }

  &__clear-btn {
    position: absolute;
    right: $spacing-100;
    bottom: $spacing-250 + 2px;
    background: transparent;
    border: 0;
    z-index: 99;

    &:hover,
    &:focus {
      outline: none;
      cursor: pointer;

      svg path {
        fill: $accent-600;
      }
    }
  }
}
