// Bootstrap Grid only
//
// Includes relevant variables and mixins for the flexbox grid
// system, as well as the generated predefined classes (e.g., `.col-sm-4`).

//
// Box sizing, responsive, and more
//

@at-root {
  /* stylelint-disable at-rule-no-vendor-prefix */
  @-ms-viewport { width: device-width; }
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

// Grid mixins
//
@import "grid/grid-settings";
@import "grid/mixins/clearfix";
@import "grid/mixins/breakpoints";
@import "grid/mixins/grid-framework";
@import "grid/mixins/grid";
@import "grid/mixins/float";
@import "grid/mixins/screen-reader";
@import "grid/mixins/text-truncate";
@import "grid/mixins/text-hide";
@import "grid/mixins/visibility";
@import "grid/custom";
@import "grid/grid";

//utilities in bs these are individual files.
@import "grid/utilities/align";
@import "grid/utilities/clearfix";
@import "grid/utilities/flex";
@import "grid/utilities/float";
@import "grid/utilities/screenreaders";
@import "grid/utilities/text";
@import "grid/utilities/visibility";
@import "grid/order";

