@import "../../stylesheets/base";

.config-view {
  $root: &;

  &__main {
    padding: $spacing-500 0 $spacing-1000;
    background: $ui-100;
    min-height: 100%;
  }
}
