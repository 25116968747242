@import "../../../stylesheets/base";

.td-cell {
  position: relative;
  padding: $spacing-100;
  color: $ui-1000;
  vertical-align: middle;

  &__wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__sub-text {
    color: $ui-600;
    margin: -$spacing-50 0 0;
  }

  &__link {
    color: $accent-500;
    word-break: break-word;

    &--lapsed {
      color: $negative-500;
    }
  }

  &__line1 {
    display: flex;
    word-break: break-word;
  }

  &__flag {
    display: inline-block;
    color: $accent-500;
    font-size: #{rem(28)};
    font-weight: bold;
    margin-left: $spacing-50;
  }
}
