@import "../../stylesheets/base";

.select-list {
  $this: &;
  position: relative;
  margin-left: -$spacing-200;
  margin-right: -$spacing-200;

  strong {
    font-weight: 500;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    border-bottom: 1px solid $ui-200;
  }

  &__radio {
    opacity: 0;
    position: absolute;
    width: 0;
    margin: 0;
    height: 0;
  }

  &__label {
    color: $ui-800;
    display: block;
    padding: $spacing-200 $spacing-250 $spacing-150 $spacing-200;
    position: relative;
    margin-bottom: 0;
    border-bottom: 1px solid $ui-200;
    z-index: 1;

    &--has-prefix {
      display: flex;
    }
  }

  &__prefix {
    display: inline-block;
    position: absolute;
    left: $spacing-400;
    top: $spacing-200;
    font-family: $font-body;
    line-height: #{rem(28)};
    font-weight: 500;
    width: #{rem(22)};
  }

  &__tick {
    position: absolute;
    top: $spacing-200;
    right: $spacing-200;
    display: none;
  }

  &__text {
    font-family: $font-body;
    margin-bottom: 0;
    padding-right: $spacing-100;
  }

  &__title {
    display: block;
    margin-bottom: 0;
  }

  &__faux-background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  &__label:hover,
  &__radio:focus ~ {
    #{$this}__faux-background {
      background: $ui-100;
    }
  }

  &__radio:checked ~ {
    #{$this}__faux-background {
      background: $accent-100;
    }
    #{$this}__tick {
      display: block;

      svg path {
        stroke: transparent;
        fill: $accent-500;
        transition: all 0.25s ease-in-out;
      }
    }

    #{$this}__text,
    #{$this}__title,
    #{$this}__prefix {
      color: $accent-700;
    }
  }
}
