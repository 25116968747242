@import "../../../stylesheets/base";

.table {
  border-top: $table-border;
  word-break: break-all;
  min-width: 100%;
  max-width: 500%;
  table-layout: fixed;

  &__container {
    display: block;
    margin: 0 auto;
    width: 100%;
    overflow-x: scroll;
  }

  &__body {
    &::before {
      content: "";
    }
    position: relative;

  }
}
