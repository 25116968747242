html {
  font-family: $font-body;
}

body {
  font-size: $paragraph-md-font-size;
  line-height: $paragraph-md-line-height;
  letter-spacing: $paragraph-md-letter-spacing;
  font-weight: $paragraph-md-font-weight;
}
