@import "src/stylesheets/base";

.patient-invite-modal {
  $root: &;

  &__options {
    margin-top: $spacing-200;
  }

  &__radio-group {
    label {
      padding-left: 0;
    }
  }

  &__buttons {
    margin-top: $spacing-400;
    text-align: right;
  }

  &__send {
    margin-left: $spacing-150;
  }

  &__contact-number {
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 1rem;
    }

    &__country-code {
      flex-basis: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      select {
        box-shadow: 0 0 0 2px $ui-200;
      }
    }

    &__number {
      flex-grow: 1;
    }
  }
}
