@import "../../../stylesheets/base";

.text {
  margin-bottom: $spacing-50;
  color: $ui-800;

  &.truncate {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-xs {
    font-size: $paragraph-sm-font-size/2;
    line-height: $paragraph-sm-line-height/2;
    letter-spacing: $paragraph-sm-letter-spacing/2;
    font-weight: $paragraph-sm-font-weight/2;
  }

  &-sm {
    font-size: $paragraph-sm-font-size;
    line-height: $paragraph-sm-line-height;
    letter-spacing: $paragraph-sm-letter-spacing;
    font-weight: $paragraph-sm-font-weight;
  }

  &-md {
    font-size: $paragraph-md-font-size;
    line-height: $paragraph-md-line-height;
    letter-spacing: $paragraph-md-letter-spacing;
    font-weight: $paragraph-md-font-weight;
  }

  &-lg {
    font-size: $paragraph-lg-font-size;
    line-height: $paragraph-lg-line-height;
    letter-spacing: $paragraph-lg-letter-spacing;
    font-weight: $paragraph-lg-font-weight;
  }
}
