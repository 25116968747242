@import "../../stylesheets/base";

.order-patient-detail-block {
  $root: &;

  &__label {
    display: block;
    color: $ui-600;
    font-size: #{rem(14)};
    font-weight: 500;
    margin-bottom: #{rem(2)};
  }

  &__list {
    margin: $spacing-100 0 $spacing-200;
  }

  &__list-item {
    border-bottom: 1px solid $ui-200;
    padding-bottom: $spacing-100;
    margin-bottom: $spacing-100;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__email {
    word-wrap: break-word;
  }

  &__button {
    width: 100%;
  }

}
