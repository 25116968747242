@import "../../stylesheets/base";

.order-line {
  display: flex;
  flex-direction: row; //this will allow flex-end to move item to the right
  align-items: center;
  margin-bottom: $spacing-150;

  &__indicator {
    position: relative;
    display: inline-block;
    min-width: #{rem(24)};
    height: #{rem(24)};
    border-radius: 6px;
    background: $accent-200;
    color: $accent-700;
    text-align: center;
    margin-right: $spacing-150;

    svg path {
      fill: $accent-700;
    }

    &--rejected {
      background: $negative-200;
    }

    &--amended {
      background: $warning-300;
    }
  }

  &__indicator-icon {
    position: absolute;
    top: #{rem(2)};
    left: $spacing-50;
  }

  &__indicator-qty {
    color: $accent-700;
    font-weight: 500;

    &--rejected {
      color: $negative-700;
    }

    &--amended {
      color: $warning-800;
    }
  }

  &__qty {
    font-family: $font-heading;
    font-weight: 500;
    padding-left: $spacing-150;
    margin-left: auto;

    &--rejected {
      text-decoration: line-through;
      color: $negative-500;
    }
  }

  &__product-name {
    &--rejected {
      text-decoration: line-through;
      color: $negative-500;
    }
  }

  &__form-buttons {
    min-width: 100px;
  }

  &__spacer-dot {
    min-width: 10px;
    text-align: center;
    padding-bottom: 7px;
  }
}
