@import "../../stylesheets/base";

.patient-summary-block {
  $root: &;

  &__table {
    width: 100%;
    border-top: 0;

    tr {
      border-bottom: 0;
    }

    th {
      font-size: #{rem(14)};
    }
  }

  &__title {
    margin-left: #{rem(8)};
  }
}
