@import "../../stylesheets/base";

.pom-modal {
  height: 100%;
  max-height: 800px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-sizing: border-box;
  gap: 2rem;

  &__forms-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    max-height: 640px;

    &__form {
      display: flex;
      flex-direction: column;
      flex: 1 1 50%;

      h3 {
        margin-bottom: 1rem;
      }

      &-content {
        flex: 1;
        overflow-y: scroll;

        &__answer {
          padding-bottom: 0.5rem;
          margin-bottom: 0.5rem;
          box-shadow: 0 -1px 0 0 #dbe0e5 inset;

          label {
            color: $ui-600;
          }
        }
      }
    }
  }

  &__button-container {
    flex: 0;
    text-align: right;

    > * {
      margin-left: 2rem;
    }

    &__quantity-selector {
      display: inline-block;
      vertical-align: top;
    }
  }

  &__images-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }

  &__image-preview {
    max-height: 80px;
    max-width: 120px;
  }
}
