@import "../../stylesheets/base";

.prescription-details {
  $root: &;

  &__main {
    padding: $spacing-500 0;
    background: $ui-100;
    min-height: 100%;

    &__container {
      width: 100% !important;
      max-width: $breakpoint-xl !important;
    }
  }

  &__card {
    margin-bottom: $spacing-150;
  }

  &__orderlines {
    margin-top: 1rem;

    li > div:last-of-type {
      margin-bottom: 0;
    }
  }

  &__error {
    text-align: center;
    padding: $spacing-300 0;

    &-text {
      color: $negative-600;
      margin-top: $spacing-100;
    }

    svg {
      path {
        fill: $negative-600;
      }
    }
  }
}
