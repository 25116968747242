@import "../../stylesheets/base";

.order-details {
  $root: &;

  &__main {
    padding: $spacing-500 0;
    background: $ui-100;
    min-height: 100%;
  }

  &__card {
    margin-bottom: $spacing-150;
  }

  &__refund-card {
    margin-top: $spacing-150;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    * {
      margin: 0;
    }

    &--failed {
      border-color: $negative-500;

      h4,
      h5,
      p {
        color: $negative-500;
      }
    }

    &--success {
      border-color: $accent-500;

      h4,
      h5 {
        color: $accent-500;
      }

      p {
        font-weight: 600;
      }
    }
  }

  &__notes-card {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &__notes-input {
      width: 100%;
      min-height: 250px;
    }

    &__buttons {
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }
  }

  &__manage-prescription-card {
    margin-top: $spacing-150;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    * {
      width: 100%;
    }
  }

  &__error {
    text-align: center;
    padding: $spacing-300 0;

    &-text {
      color: $negative-600;
      margin-top: $spacing-100;
    }

    svg {
      path {
        fill: $negative-600;
      }
    }
  }
}
