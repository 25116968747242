@import "../../stylesheets/base";

.table-empty-state {
  $root: &;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: #{rem(260)};

  &__icon {
    margin-bottom: $spacing-300;
  }

  &__text {
    max-width: 80%;
    display: flex;
    word-break: break-word;
    text-align: center;
  }
}
