@import "../../../stylesheets/base";

.th-cell {
  padding: $spacing-100;
  vertical-align: middle;
  color: $ui-600;

  &__inner {
    display: flex;
    align-items: center;
  }

  &__sort {
    display: block;
    position: relative;
    background: transparent;
    height: #{rem(18)};
    width: #{rem(20)};
    margin-left: #{rem(6)};
    border: 0;

    &::before,
    &::after {
      border: 4px solid transparent;
      content: "";
      display: block;
      height: 0;
      right: 50%;
      margin-right: -3px;
      top: 50%;
      position: absolute;
      width: 0;
    }

    &::before {
      border-bottom-color: $ui-400;
      margin-top: -$spacing-100;
    }

    &::after {
      border-top-color: $ui-400;
      margin-top: 2px;
    }

    &--ascending {
      &::after {
        border-top-color: $ui-1000;
      }
    }

    &--descending {
      &::before {
        border-bottom-color: $ui-1000;
      }
    }
  }
}
