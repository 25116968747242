@import "../../stylesheets/base";

.print-prescription {
  &__container {
    padding: 2rem;
    width: 100%;
    max-width: $breakpoint-lg;
    margin-inline: auto;
  }

  &__row {
    width: 100%;
    display: flex;
  }

  &__drugs-title {
    height: 1.5rem;
    align-self: center;
  }

  &__col {
    flex-direction: column;
  }

  &__heading {
    text-align: center;
  }

  &__bold-title {
    font-weight: bold;
    font-size: #{rem(17)};
  }

  &__bold-subtitle {
    font-weight: bold;
  }

  &__logo {
    margin: 1rem 0;
  }

  &__drugs {
    height: 250px;
  }

  &__no-of-prescribed-items-title {
    font-size: #{rem(10)};
    font-weight: bold;
  }

  &__no-of-prescribed-items-content {
    font-size: #{rem(10)};
  }
}

.utils {
  &__flex-50 {
    width: 50%;
    flex: 1 1 50%;
  }

  &__flex-25 {
    width: 25%;
    flex: 1 1 25%;
  }

  &__flex-grow {
    flex: 1;
  }

  &__flex-shrink {
    flex: 0 1;
    min-width: 200px;
  }

  &__center {
    text-align: center;
    margin: auto;
  }

  &__flex-parent {
    display: flex;
  }
}

.border {
  border: 1px solid black;
  padding: 1rem;
}
