@import "../../stylesheets/base";

.conditions-list {
  $root: &;

  &__loading {
    min-height: 400px;
  }

  &__no_conditions {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding-top: #{rem(260)};
  }

  &__icon {
    margin-bottom: $spacing-300;
  }

  &__text {
    max-width: #{rem(392)};
    display: flex;
    word-break: break-word;
    text-align: center;
  }
}

.cell {
  &--narrow {
    width: 300px;
  }
}
