@import "../../stylesheets/base";

.order-detail-block {
  $root: &;

  &__table {
    width: 100%;

    tr {
      border-bottom: 0;
    }

    th {
      font-size: #{rem(14)};
    }
  }

  &__card-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__cta {
    margin-left: auto;
    margin-bottom: 0;
  }

  &__tracking-info {
    margin-bottom: 1rem;

    * {
      display: inline;
      font-size: 17px;
    }
  }
}
