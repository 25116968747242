@import "stylesheets/base";

.rich-text-editor {
  &__label {
    display: block;
  }

  &__helper-text {
    display: block;
    color: $ui-500;
    margin-top: #{rem(-8)};
  }

  &__caption {
    color: $ui-500;

    &--error {
      color: $negative-500;
    }
  }

  &__container {
    position: relative;
  }

  &__textarea {
    background: $ui-200;
    box-shadow: 0 0 0 1px $ui-200;
    color: $ui-1000;
    padding: $spacing-100 $spacing-200;
    width: 100%;
    position: relative;
    border-radius: 8px;
    margin-top: #{rem(8)};
    margin-bottom: #{rem(8)};
    overflow-y: scroll;

    &:hover:not(:read-only) {
      box-shadow: 0 0 0 1px $ui-300;
      transition: box-shadow 0.25s ease-in-out,
        background-color 0.25s ease-in-out;
    }

    &:focus:not(:read-only) {
      box-shadow: 0 0 0 3px $accent-600;
      outline: none;
      transition: box-shadow 0.25s ease-in-out,
        background-color 0.25s ease-in-out;
    }

    &--sm {
      height: 4rem;
    }

    &--md {
      height: 8rem;
    }

    &--lg {
      height: 10rem;
    }

    &--error {
      box-shadow: 0 0 0 3px $negative-500;
      outline: none;
      transition: box-shadow 0.25s ease-in-out,
        background-color 0.25s ease-in-out;

      &:focus:not(:read-only) {
        box-shadow: 0 0 0 3px $negative-500;
      }
    }
  }

  &__placeholder {
    position: absolute;
    top: $spacing-100;
    left: $spacing-200;
    color: $ui-700;
  }

  &--bold {
    font-weight: 600;
  }

  &--italic {
    font-style: italic;
  }

  &--underline {
    text-decoration: underline;
  }

  &__paragraph {
    font-size: $paragraph-md-font-size;
    line-height: $paragraph-md-line-height;
    letter-spacing: $paragraph-md-letter-spacing;
    font-weight: $paragraph-md-font-weight;
  }

  &__h1 {
    font-size: $title-lg-font-size;
    line-height: $title-lg-line-height;
    letter-spacing: $title-lg-letter-spacing;
    font-weight: $title-lg-font-weight;
  }

  &__h2 {
    font-size: $title-md-font-size;
    line-height: $title-md-line-height;
    letter-spacing: $title-md-letter-spacing;
    font-weight: $title-md-font-weight;
  }

  &__h3 {
    font-size: $title-sm-font-size;
    line-height: $title-sm-line-height;
    letter-spacing: $title-sm-letter-spacing;
    font-weight: $title-sm-font-weight;
  }

  &__ol {
    list-style: decimal;
  }

  &__ul {
    list-style: disc;
  }

  &__list-item {
    margin-left: 2rem;
  }

  &__nested-list-item {
    list-style-type: none;
    margin-left: 2.5rem;
  }
}
