@import "../../stylesheets/base";

.submit-refund-modal {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  * {
    margin: 0;
  }

  h4 {
    margin-right: 1.5rem;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    button {
      flex: 1 1 50%;
      margin: 0;
    }
  }
}
