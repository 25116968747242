@import "../../stylesheets/base";

.patient-dependant-block {
  $root: &;

  &__table {
    width: 100%;
    border-top: 0;

    :last-child {
      border: 0;
    }

    th {
      font-size: #{rem(14)};
    }
  }

  &__title {
    margin-left: #{rem(8)};
  }
}
