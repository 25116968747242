@import "../../stylesheets/base";

.orders-audit {
  $root: &;

  &__loading {
    min-height: 400px;
  }

  &__no_orders {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding-top: #{rem(260)};
  }

  &__no_orders_icon {
    margin-bottom: 24px;
  }

  &__no_orders_text {
    max-width: #{rem(392)};
    display: flex;
    word-break: break-word;
    text-align: center;
  }
}
