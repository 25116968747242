@import "stylesheets/base";

$variants: (
  ui $ui-800 $ui-0,
  accent $accent-800 $ui-0,
  positive $positive-800 $ui-0,
  warning $warning-800 $ui-0,
  negative $negative-800 $ui-0
);

$sizes: (
  sm $spacing-250,
  md $spacing-300,
  lg $spacing-400,
  full-screen $spacing-800
);

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__animation {
    position: relative;
    border: 2px solid;
    border-radius: 50%;
    animation: rotate 0.75s linear infinite;

    &--light {
      @each $variant in $variants {
        &--#{nth($variant, 1)} {
          border-color: #{nth($variant, 3)};
          border-top-color: #{nth($variant, 2)};
        }
      }
    }

    &--dark {
      @each $variant in $variants {
        &--#{nth($variant, 1)} {
          border-color: #{nth($variant, 2)};
          border-top-color: #{nth($variant, 3)};
        }
      }
    }

    @each $size in $sizes {
      &--#{nth($size, 1)} {
        width: #{nth($size, 2)};
        height: #{nth($size, 2)};
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.landing-page-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
