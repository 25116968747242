@import "../../stylesheets/base";

.add-product-modal {
  $root: &;

  &__title {
    margin-bottom: $spacing-150;
  }

  &__list-container {
    height: 250px;
    overflow-y: scroll;
  }

  &__button-container {
    text-align: right;
  }

  &__button {
    margin-bottom: 0;
    margin-top: $spacing-200;
  }

  &__search {
    margin-left: 0;
    padding-left: 0;
  }
}
