@import "../../stylesheets/base";

.status-text {
  &--tag {
    position: relative;
    display: inline-block;
    color: $accent-800;
    size: #{rem(16)};
    line-height: #{rem(20)};
    font-weight: 500;
    background: $accent-200;
    border-radius: #{rem(14)};
    padding: $spacing-50 $spacing-150 $spacing-50 $spacing-300;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: #{rem(10)};
      display: block;
      background: $accent-500;
      width: $spacing-100;
      height: $spacing-100;
      border-radius: 100%;
    }
  }

  &--lapsed {
    color: $negative-800;
    background: $negative-200;

    &::after {
      background: $negative-500;
    }
  }
}
