@import "../../stylesheets/base";

.orders {
  $root: &;

  &__loading {
    min-height: 400px;
  }

  &__cell {
    &--md {
      min-width: 200px !important;
    }

    &--xl {
      min-width: 250px !important;
    }

    &__button {
      margin: 0 !important;
      font-size: 1rem;
      text-decoration: underline;
    }
  }

  &__delivery-info-col {
    div span {
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
}
