$base-spacing-unit: 8px;

$spacing-25: $base-spacing-unit * 0.25;
$spacing-50: $base-spacing-unit * 0.5;
$spacing-100: $base-spacing-unit;
$spacing-150: $base-spacing-unit * 1.5;
$spacing-200: $base-spacing-unit * 2;
$spacing-250: $base-spacing-unit * 2.5;
$spacing-300: $base-spacing-unit * 3;
$spacing-350: $base-spacing-unit * 3.5;
$spacing-400: $base-spacing-unit * 4;
$spacing-450: $base-spacing-unit * 4.5;
$spacing-500: $base-spacing-unit * 5;
$spacing-550: $base-spacing-unit * 5.5;
$spacing-600: $base-spacing-unit * 6;
$spacing-650: $base-spacing-unit * 6.5;
$spacing-700: $base-spacing-unit * 7;
$spacing-800: $base-spacing-unit * 8;
$spacing-900: $base-spacing-unit * 9;
$spacing-1000: $base-spacing-unit * 10;
$spacing-1200: $base-spacing-unit * 12;
$spacing-1400: $base-spacing-unit * 14;
$spacing-1600: $base-spacing-unit * 16;
$spacing-1800: $base-spacing-unit * 18;
$spacing-2000: $base-spacing-unit * 20;
