@import "../../stylesheets/base";

.table-header {
  $root: &;
  display: flex;
  align-items: center;
  padding: $spacing-200 0 0;

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    margin-left: auto;
    text-align: right;
  }

  &__search {
    width: #{rem(310)};
  }

  &__search--with-filter-buttons::before {
    content: "";
    position: absolute;
    left: 0;
    height: $spacing-300;
    width: 1px;
    background: $ui-200;
  }

  &__title {
    margin: 0 0 $spacing-100 $spacing-100;
  }

  &__action--with-search {
    margin-left: $spacing-300;
    margin-bottom: 0;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: -$spacing-150;
      height: $spacing-300;
      width: 1px;
      background-color: $ui-200;
    }
  }

}
