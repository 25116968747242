@import "../../stylesheets/base";

.product-image {
  width: #{rem(112)};

  &__image {
    width: 100%;
  }

  &__error {
    border-radius: $spacing-100;
    background-color: $ui-100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
