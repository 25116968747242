@import "stylesheets/base";

.rich-text-viewer {
  &--bold {
    font-weight: 600;
  }

  &--italic {
    font-style: italic;
  }

  &--underline {
    text-decoration: underline;
  }

  &__ol {
    list-style: decimal;
  }

  &__ul {
    list-style: disc;
  }

  &__list-item {
    margin-left: 2rem;
  }

  &__nested-list-item {
    list-style-type: none;
    margin-left: 2.5rem;
  }
}
