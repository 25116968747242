@import "../../stylesheets/base";

.group-form {
  $root: &;

  &__actions-container {
    display: flex;
  }

  &__action-button {
    margin-left: auto;
  }
}
