@import "../../stylesheets/base";

.website-config-form {
  $root: &;

  &__title {
    margin-bottom: $spacing-150;
  }

  &__sub-title {
    margin-bottom: $spacing-150;
  }

  &__form-col-wrap {
    display: flex;
  }

  &__form-col {
    width: 50%;

    &--wide {
      width: 100%;
    }

    &:not(:last-child) {
      margin-right: $spacing-300;
    }
  }

  &__form-col + &__form-col {
    margin-top: 3rem;
  }

  &__input {
    margin-bottom: $spacing-200;
  }

  &__helper-text {
    margin-bottom: $spacing-150;
    margin-top: -$spacing-100;
    color: $ui-500;
    display: block;
    font-size: $paragraph-md-font-size;
    font-weight: $paragraph-md-font-weight;
    letter-spacing: $paragraph-md-letter-spacing;
    line-height: $paragraph-md-line-height;
  }

  &__card {
    margin-bottom: $spacing-150;
  }

  &__checkbox {
    margin-bottom: 0;
  }

  &__button-container {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing-800;
  }

  &__delete-button {
    margin-top: $spacing-300;
  }

  &__footer {
    background: #fff;
    min-height: $spacing-800;
    border-top: 1px solid #3333;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    margin-left: auto;
    padding-right: $spacing-150;
  }

  &__unsaved-changes {
    margin-left: $spacing-300;
  }

  &__actions-container {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    margin-left: auto;
  }

  &__action-button {
    min-height: $spacing-500;
    margin: $spacing-150;
    display: flex;
  }

  &__ranked-products {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &__heading {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      justify-content: flex-end;
      align-items: center;
      padding: 0.5rem 0.5rem 0;
    }

    &__placeholder {
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__ranked-product {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: flex-end;
    align-items: center;

    padding: 0.5rem;
    border-radius: 0.75rem;

    background: #ffffff;
    filter: drop-shadow(0 4px 16px rgba(219, 224, 229, 0.5));

    &__rank {
      flex: 0;
      min-width: 50px;
      text-align: center;
      font-weight: bold;
    }

    &__title {
      flex: 1;
    }

    &__heading {
      font-weight: bold;
    }

    &__button {
      flex: 0;
      margin: 0;
      align-self: stretch;

      svg {
        max-width: 16px;

        path {
          fill: $accent-500;
        }
      }
    }
  }
}
