@import "../../stylesheets/base";

.breadcrumbs {
  $root: &;
  display: block;
  padding: $spacing-200;
  font-family: $font-heading;
  font-weight: bold;
  font-size: #{rem(25)};
  line-height: #{rem(32)};
  letter-spacing: #{rem(-0.4)};
  min-height: #{rem(64)};


  &__list-item {
    display: inline-block;
    position: relative;
    padding-right: $spacing-250;

    &:not(:last-child)::after {
      content: "";
      display: block;
      position: absolute;
      border-top: $ui-600 2px solid;
      border-right: $ui-600 2px solid;
      width: #{rem(8)};
      height: #{rem(8)};
      right: $spacing-50;
      top: 50%;
      transform: rotate(45deg) translate(-50%);
    }
  }

  &__link {
    color: $ui-500;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }

    &--active {
      color: $ui-1000;
    }
  }
}
