@import "../../../stylesheets/base";

$icons: (
  xxs $icon-height-xxs $icon-width-xxs,
  xs $icon-height-xs $icon-width-xs,
  sm $icon-height-sm $icon-width-sm,
  md $icon-height-md $icon-width-md,
  lg $icon-height-lg $icon-width-lg,
  xl $icon-height-xl $icon-width-xl,
  xxl $icon-height-xxl $icon-width-xxl,
);

.icon {
  display: inline-block;

  &-svg {
    height: 100%;
    width: 100%;
  }

  @each $icon in $icons {
    &--#{nth($icon, 1)} {
      height: #{nth($icon, 2)};
      width: #{nth($icon, 3)};
    }
  }
}
