@import "../../stylesheets/base";

.actions-bar {
  background: #fff;
  min-height: 64px;
  border-top: 1px solid #3333;
  display: flex;

  &__end {
    position: relative;
    align-items: center;
    display: flex;
    margin-left: auto;
    padding-right: $spacing-150;
  }

  &__selected {
    position: relative;
    padding-right: $spacing-150;
    margin-right: $spacing-150;

    &::after {
      content: "";
      width: 1px;
      min-height: #{rem(24)};
      border-left: 1px solid $ui-200;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }

  &__action {
    margin: $spacing-150 0 $spacing-150 $spacing-150;
  }
}
