@import "../../stylesheets/base";

.tab-set {
  $root: &;

  &__list-item {
    display: inline-block;
  }

  &__tab {
    display: block;
    border: 0;
    background: none;
    padding: $spacing-50 $spacing-100;
    font-family: $font-heading;
    font-size: #{rem(17)};
    line-height: #{rem(22)};
    text-align: center;
    color: $ui-600;
    border-bottom: 2px solid transparent;

    &--active {
      color: $accent-700;
      border-bottom: 2px solid $accent-500;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
