@import "../../stylesheets/base";

.condition-group-form {
  $root: &;

  &__title {
    margin-bottom: $spacing-150;
  }

  &__sub-title {
    margin-bottom: $spacing-150;
  }

  &__form-col-wrap {
    display: flex;
  }

  &__form-col {
    width: 50%;

    &--wide {
      width: 75%;
    }

    &:not(:last-child) {
      margin-right: $spacing-300;
    }
  }

  &__input {
    margin-bottom: $spacing-200;
  }

  &__card {
    margin-bottom: $spacing-150;
    max-width: $breakpoint-xl;
    margin-inline: auto;
  }

  &__checkbox {
    margin-bottom: 0;
  }

  &__button-container {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing-800;
  }

  &__delete-button {
    margin-top: $spacing-300;
  }

  &__footer {
    background: #fff;
    min-height: $spacing-800;
    border-top: 1px solid #3333;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    margin-left: auto;
    padding-right: $spacing-150;
  }

  &__unsaved-changes {
    margin-left: $spacing-300;
  }

  &__actions-container {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    margin-left: auto;
  }

  &__action-button {
    min-height: $spacing-500;
    margin: $spacing-150;
    display: flex;
  }
}
