//styles for temporary landing page to provide test functioanlity for client

.landing-page {
  margin-top: 100px;
  height: 100px;

  &__temp-title {
    margin-bottom: 20px;
  }

  &__temp-button {
    margin-right: 20px;
  }
}
