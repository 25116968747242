@import "../../stylesheets/base";

.orders-print {
  $root: &;

  &__patient_details-container {
    display: flex;
    flex-direction: row;

    h5 {
      margin-right: $spacing-100;
    }
  }

  @media screen {
    &__container {
      margin-bottom: 50px;
    }
  }

  @media print {
    &__container {
      page-break-after: always;
      font-family: $font-body;
      margin-left: 50px;
      margin-top: 50px;
    }

    &__patient_details {
      margin-top: $spacing-250;
      margin-bottom: 50px;
    }

    &__practice_details {
      margin-top: 50px;
    }

    &__details {
      padding-left: $spacing-250;
      margin-right: $spacing-250;
    }
  }
}
