@import "../../stylesheets/base";

.patient-pmed-form-modal {
  $root: &;

  min-height: 450px;

  &__title {
    margin-bottom: $spacing-150;
    max-width: 512px;
  }

  &__container {
    min-height: 450px;
    margin-top: $spacing-150;
    overflow: scroll;
  }

  &__button-container {
    text-align: right;
    margin-top: $spacing-300;
  }

  &__label {
    font-weight: 500;
    font-family: $font-body;
    color: $ui-600;
  }

  &__answer {
    color: $ui-1000;
  }

  &__question-answer-container {
    & + & {
      border-top: 1px solid $ui-200;
      padding-top: $spacing-50;
      padding-bottom: $spacing-50;
    }
  }

  &__error {
    text-align: center;
    padding: $spacing-300 0;
    min-height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-text {
      color: $negative-600;
      margin-top: $spacing-100;
    }

    svg {
      path {
        fill: $negative-600;
      }
    }
  }
}
