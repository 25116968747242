@import "stylesheets/base";

.form-footer {
  $root: &;

  background: #fff;
  min-height: $spacing-800;
  border-top: 1px solid #3333;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  margin-left: auto;
  padding-right: $spacing-150;

  &__unsaved-changes {
    margin-left: $spacing-300;
  }

  &__actions-container {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    margin-left: auto;
  }

  &__action-button {
    min-height: $spacing-500;
    margin: $spacing-150;
    display: flex;
  }
}
