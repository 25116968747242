@import "../../stylesheets/base";

.card {
  position: relative;
  color: $ui-1000;
  background: $ui-0;
  text-decoration: none;
  display: block;
  padding: $spacing-200;
  border: solid 1px $ui-200;
  border-radius: $spacing-150;
  transition: all 0.5s;

  &__text {
    margin-bottom: 0;
  }

  &__icon {
    position: absolute;
    top: $spacing-200;
    left: $spacing-200;
  }

  &__arrow {
    position: absolute;
    top: $spacing-200;
    right: $spacing-150;
  }

  &--has-icon {
    padding-left: $spacing-650;
  }

  &--is-link {
    padding-right: $spacing-600;

    &:hover {
      background: $ui-100;
    }

    &:focus {
      outline: none;
      border-color: $accent-600;
      box-shadow: inset 0 0 0 2px $accent-600, 0 0 0 2px $accent-600;
    }
  }
}
