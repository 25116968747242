//breakpoints
$breakpoint-xs: 0; //only used in bs3
$breakpoint-sm: 696px;
$breakpoint-md: 792px;
$breakpoint-lg: 1080px;
$breakpoint-xl: 1272px;

//grid gutters
$gutter-width-xs: 16px;
$gutter-width-sm: 16px;
$gutter-width-md: 24px;
$gutter-width-lg: 32px;
$gutter-width-xl: 32px;

//only used in Bs4
$container-max-width-sm: 696px + $gutter-width-sm*2;
$container-max-width-md: 744px + $gutter-width-md*2;
$container-max-width-lg: 926px + $gutter-width-lg*2;
$container-max-width-xl: 928px + $gutter-width-xl*2;
