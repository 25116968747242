@import "../../stylesheets/base";

.site-nav {
  display: flex;
  align-items: center;
  padding: 0 $spacing-50;
  margin-bottom: 0;
  box-shadow: 0 1px 0 $ui-200;

  &__logo {
    margin-right: $spacing-200;
  }

  &__list {
    display: inline-block;
  }

  &__link {
    display: inline-block;
    padding: $spacing-150 $spacing-100;
    text-decoration: none;
    margin-right: $spacing-100;
    color: $ui-600;
    font-family: $font-heading;
    font-size: #{rem(17)};
    line-height: #{rem(22)};

    &:focus,
    &:hover {
      position: relative;
      outline: 0;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 2px;
        background: $accent-500;
      }
    }
  }

  &__link--active {
    position: relative;
    background: $accent-100;
    color: $accent-700;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background: $accent-500;
    }
  }

  &__end {
    position: relative;
    align-items: center;
    display: flex;
    margin-left: auto;

    &::before {
      content: "";
      width: 1px;
      height: #{rem(24)};
      border-left: 1px solid $ui-200;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__logout {
    margin-left: $spacing-200;
  }

  &__pharmacy {
    &::before {
      content: "";
      width: 1px;
      height: #{rem(24)};
      border-left: 1px solid $ui-200;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
}
