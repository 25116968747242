@import "../../stylesheets/base";

.order-status-modal {
  $root: &;

  &__form {
    margin-top: $spacing-200;
  }

  &__buttons {
    margin-top: $spacing-400;
    text-align: right;
  }

  &__submit {
    margin-left: $spacing-150;
  }

  &__disabled-reason {
    margin-top: $spacing-400;
    color: $negative-500;
  }

  &__delivery-tracking-info-container {
    margin-top: $spacing-400;

    label {
      margin: none;
    }

    div {
      margin-bottom: 1rem;
    }
  }
}
