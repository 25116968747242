@import "../../stylesheets/base";

$sizes: (
  lg $radio-lg-diameter $radio-lg-spacing $radio-lg-border-width 1rem,
  md $radio-md-diameter $radio-md-spacing $radio-md-border-width 1rem,
  sm $radio-sm-diameter $radio-sm-spacing $radio-sm-border-width 1rem
);

$variants: (
  ui $ui-500 $ui-1000 $ui-0 $ui-200 $ui-0 $ui-800 $ui-300 $ui-800 $accent-600,
  accent $ui-500 $accent-500 $ui-0 $ui-200 $ui-0 $accent-600 $ui-300 $accent-700
  $accent-600
);

.input__checkbox-container {
  position: relative;
  padding-top: $spacing-100;

  @each $size in $sizes {
    &--#{nth($size, 1)} {
      .input__checkbox:checked ~ .input__checkbox-circle {
        &::before {
          border-width: #{nth($size, 4)};
        }
      }

      .input__checkbox-indicator {
        height: #{nth($size, 2)};
        width: #{nth($size, 2)};

        &::before {
          height: #{nth($size, 2)};
          width: #{nth($size, 2)};
        }

        &::after {
          height: #{nth($size, 5)};
          width: #{nth($size, 5)};
        }
      }

      .input__checkbox-label {
        margin-bottom: 0;
      }
    }
  }

  @each $variant in $variants {
    &--#{nth($variant, 1)} {
      .input_checkbox-indicator {
        position: relative;

        &::before {
          border-color: #{nth($variant, 2)};
        }

        &:active::before {
          background-color: #{nth($variant, 8)};
        }
      }

      .input__checkbox:checked ~ .input__checkbox-indicator {
        &::before {
          background-color: #{nth($variant, 3)};
          border-color: #{nth($variant, 3)};
        }
      }
    }
  }

  .input__checkbox-label {
    position: relative;

    &--md {
      font-size: #{rem(18)};
    }
  }

  &.disabled {
    opacity: 0.24;
  }

  .input__checkbox-indicator {
    position: relative;
    display: block;
    margin-right: $spacing-150;

    &::before,
    &::after {
      position: absolute;
      content: "";
      border-radius: $spacing-100;
      border-style: solid;
      border-width: $radio-border-width;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &::after {
      border: transparent;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .input__checkbox {
    opacity: 0;
    position: absolute;
    width: 0;
    margin: 0;
    height: 0;

    &-text {
      @include sr-only;
    }

    &:checked ~ .input__checkbox-indicator::after {
      background-image: url("../../assets/icons/lawsat/checkmark.svg");
      background-size: 100%;
    }

    &:disabled ~ .input__checkbox-indicator {
      pointer-events: none;
    }

    &:focus ~ .input__faux-background {
      border: 0;
      box-shadow: 0 0 0 2px $accent-600, inset 0 0 0 1px $accent-600;
    }
  }
}

.input__header-select {
  padding-top: 0;
  display: flex;
}
